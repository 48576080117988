<template>
  <main class="min-h-screen bg-gray-100">
    <div class="max-w-xl mx-auto p-2 lg:p-8">
      <sqr-progress v-show="loading" />
      <sqr-error-banner :error="error" />
      <div class="bg-white border rounded shadow p-4" v-if="integration">
        <h1 class="text-xl font-bold">
          {{ $t('int_centaur_title') }}
        </h1>

        <div class="py-4">
          <h2 class="text-md font-bold mt-3">
            {{ $t('int_centaur_accounts') }}
          </h2>

          <div
            class="py-1 grid grid-cols-3"
            v-for="(entry, index) in keycards"
            :key="entry.name"
            :class="{ 'border-t': index > 0 }"
          >
            <div class="py-2">{{ entry.name }}</div>
            <div class="py-2 truncate">
              <span v-if="entry.accountId">
                <account-name :account="entry" reverse />
              </span>
            </div>
            <div>
              <sqr-button
                color="white"
                size="sm"
                :label="$t('int_centaur_change')"
                @click="selectedKeycard = entry.name"
              />
              <sqr-button
                color="white"
                second="red"
                size="sm"
                :label="$t('int_centaur_remove')"
                @click="remove(entry.name)"
                :disabled="!canRemove(entry.name)"
              />
            </div>
          </div>
          <div>
            <sqr-button
              icon="plus"
              :label="$t('int_centaur_add')"
              color="gray"
              light
              @click="add()"
            />
          </div>
        </div>

        <div class="py-2">
          <sqr-input-checkbox
            name="tech_view"
            :placeholder="$t('int_centaur_tech')"
            v-model="techView"
          />
        </div>

        <div v-show="techView">
          <div class="py-4 -mx-4 px-4 bg-gray-100">
            <sqr-input-number
              class="mb-1"
              :label="$t('int_centaur_last_id')"
              :value="integration.lastId"
              @change="fieldSet('lastId', $event)"
            />
            <sqr-input-number
              :label="$t('int_centaur_limit')"
              :value="integration.limit"
              @change="fieldSet('limit', $event)"
            />
          </div>
          <div class="py-4">
            <sqr-input-text
              class="mb-3"
              :label="$t('int_centaur_url')"
              :value="url"
            />
            <sqr-input-text
              :label="$t('int_centaur_secret')"
              :value="integration.secret"
              @change="fieldSet('secret', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <AccountPicker
      v-show="selectedKeycard"
      @select="selected"
      @close="selectedKeycard = ''"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { prop, sortBy, clone, uniqBy } from 'ramda';

import SqrProgress from '@/sqrd-ui/SqrProgress';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputCheckbox from '@/sqrd-ui/SqrInputCheckbox';
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';

import company from './company';
import AccountPicker from '@/components/AccountPicker';
import AccountName from '@/components/AccountName';

export default {
  name: 'CompanyKeycards',
  mixins: [company],
  components: {
    SqrProgress,
    SqrErrorBanner,
    SqrButton,
    SqrInputText,
    SqrInputCheckbox,
    SqrInputNumber,
    AccountPicker,
    AccountName,
  },
  props: {
    iid: String,
  },
  computed: {
    ...mapState('integration', { integration: 'doc' }),
    ...mapState('integration', ['loading', 'error']),
    keycards() {
      const keycards = this.integration?.keycards ?? [];
      const existingNames = keycards.map(item => item.name);
      const newNames =
        this.integration?.keycardNames.filter(
          name => existingNames.indexOf(name) < 0
        ) ?? [];
      const newKeycards = newNames.map(name => ({ name }));
      const merged = [...keycards, ...newKeycards];
      return sortBy(prop('name'), merged);
    },
    url() {
      return `https://v3.wotime.ch/api/integrations/centaur/c/${this.cid}/i/${this.iid}`;
    },
  },
  data() {
    return { selectedKeycard: '', techView: false };
  },
  mounted() {
    this.sub({ path: `companies/${this.cid}/integrations/${this.iid}` });
    this.orgSub({
      path: ['companies', this.cid, 'orgs'],
      id: 'default',
    });
  },
  methods: {
    ...mapActions('integration', ['sub']),
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('updater', ['update']),
    fieldSet(field, value) {
      const doc = { [field]: value };
      this.update({ path: this.integration?.path, doc });
    },
    selected(account) {
      const keycards = clone(this.keycards);
      let entry = keycards.find(entry => entry.name === this.selectedKeycard);
      if (!entry) {
        entry = { name: this.selectedKeycard };
        keycards.push(entry);
      }
      if (entry) {
        entry.givenName = account.givenName ?? '';
        entry.familyName = account.familyName ?? '';
        entry.email = account.email ?? '';
        entry.accountId = account.id;
      }
      this.selectedKeycard = '';
      this.updateKeycards(keycards);
    },
    add() {
      const name = prompt(this.$t('int_centaur_keycard_name'));
      if (name) {
        const keycards = clone(this.keycards);
        keycards.push({ name });
        this.updateKeycards(keycards);
      }
    },
    canRemove(name) {
      if (!this.integration?.keycards) return false;
      return this.integration?.keycardNames.indexOf(name) < 0;
    },
    remove(keycardName) {
      const keycards = this.keycards.filter(({ name }) => name !== keycardName);
      this.updateKeycards(keycards);
    },
    updateKeycards(keycardsRaw) {
      const keycards = uniqBy(prop('name'), keycardsRaw);
      const keycardsMap = Object.fromEntries(
        keycards
          ?.filter(({ accountId }) => Boolean(accountId))
          ?.map(({ name, accountId }) => [name, accountId])
      );
      const doc = {
        keycards,
        keycardsMap,
      };
      this.update({ path: this.integration?.path, doc });
    },
  },
};
</script>
