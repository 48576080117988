<template>
  <div>
    <div class="flex justify-between mb-1" v-if="label">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <input
      v-bind="$attrs"
      v-on="sqrListeners"
      @change="process('change', $event.target.value)"
      @input="process('input', $event.target.value)"
      class="form-input"
      :class="inputClass"
      type="text"
      inputmode="decimal"
      :value="value"
    />
    <slot name="invalid" v-if="v && v.$error">
      <p class="mt-1 text-xs text-red-600" v-if="!v.required">
        {{ label }} is required.
      </p>
      <p class="mt-1 text-xs text-red-600" v-if="!v.between">
        {{ label }} is not between {{ v.$params.between.min }} and
        {{ v.$params.between.max }}.
      </p>
    </slot>
  </div>
</template>

<style src="./SqrInput.postcss" lang="postcss" scoped></style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrdInputNumber',
  mixins: [SqrInput],
  props: { value: Number, type: String, locale: String },
  computed: {
    separator() {
      const parts = new Intl.NumberFormat(this.locale).formatToParts(123567.89);
      const separatorPart = parts.find(part => part.type === 'separator');
      return separatorPart?.value ?? '.';
    },
  },
  methods: {
    process(name, value) {
      const pattern = new RegExp(`[^\\d${this.separator}]`, 'g');
      const unformatted = value.replace(pattern, '');
      let result = unformatted;
      const type = this.type;
      if (type === 'float') {
        result = parseFloat(unformatted);
        result = Number.isNaN(result) ? 0 : result;
      } else if (type === 'integer') {
        result = parseInt(unformatted);
        result = Number.isNaN(result) ? 0 : result;
      }
      this.$emit(name, result);
    },
  },
};
</script>
