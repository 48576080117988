<template>
  <div>
    <div class="flex justify-between pb-1" v-if="label">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <div class="input-checkbox" :class="inputClass">
      <div class="">
        <label class="checkbox" :class="optionClass">
          <input
            :id="name"
            :name="name"
            type="checkbox"
            class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            v-model="option"
          />
          <div class="ml-3">
            <span class="block text-sm leading-5 font-normal text-gray-700">
              {{ placeholder }}</span>
          </div>
        </label>
      </div>
    </div>
    <slot name="invalid" v-if="v && v.$error && !v.required">
      <p class="mt-1 text-xs text-red-600" v-if="!v.required">
        {{ label }} is required.
      </p>
      <p class="mt-1 text-xs text-red-600" v-if="!v.minLength">
        {{ label }} must have at least {{ v.$params.minLength }} items.
      </p>
    </slot>
  </div>
</template>

<style lang="postcss" scoped>
.input-checkbox.invalid {
  @apply border-red-300 bg-red-50 rounded text-red-900 p-2 -mx-2 border;
}
.checkbox {
  @apply flex items-center mb-2;
}
.checkbox:last-child {
  @apply mb-0;
}
</style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrdInputCheckbox',
  mixins: [SqrInput],
  model: { input: 'value', event: 'selected' },
  props: {
    name: String,
    value: Boolean,
    optionClass: [Array, Object, String]
  },
  computed: {
    option: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('selected', value);
        this.$emit('change', value);
      }
    }
  }
};
</script>
