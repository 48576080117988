import { omit } from 'ramda';
export default {
  props: {
    label: String,
    placeholder: String,
    value: String,
    required: Boolean,
    v: Object,
  },
  model: { prop: 'value', event: 'input' },
  computed: {
    sqrListeners() {
      if (!this.$listeners) return {};
      return omit(['input', 'change'], this.$listeners);
    },
    inputClass() {
      if (!this.v) return '';
      if (this.v.$error) {
        return 'invalid';
      } else if (this.v.$dirty && !this.v.$invalid) {
        return 'valid';
      } else {
        return '';
      }
    },
  },
};
